<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <Test /> -->
    <Header />
    <router-view />
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Test from './components/Test.vue'
import Header from '@/components/Header'
// import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    // Test,
    Header,
    // Footer
  }
}
</script>

<style lang="scss" scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
#app {
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/fowl/c_bg.png') no-repeat;
  background-size: cover;
  background-color: #0b112b;
}
</style>
