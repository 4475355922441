<template>
  <nav>
    <div>
      <div>
        <a href="/">鸡头识别系统</a>
        <p>
          <!-- 毛孔识别系统<br /> -->
        <!-- <span>Poultry Breed Identification and Qualification</span> -->
        </p>
      </div>
      <ul>
          <!-- <li>
            <router-link to="/home" :class="currAct === 'home' ? 'active' : ''">公司介绍</router-link>
          </li>
          <li>
            <router-link to="/help" :class="currAct === 'help' ? 'active' : ''">帮助</router-link>
          </li>
          <li v-if="!userName">
            <router-link to="/login" :class="currAct === 'login' ? 'active' : ''">注册/登录</router-link>
          </li> -->
          <!-- <li v-if="!!userName">
            <router-link :to="{name: 'modelingList', params: {type: ''}}" :class="currAct === 'poultry' ? 'active' : ''">控制台</router-link>
            <div class="item-list">
              <router-link :to="{name: 'chicken', params: {type: 'breed'}}">鸡品种识别</router-link>
              <router-link :to="{name: 'chicken', params: {type: 'pore'}}">鸡毛孔识别</router-link>
            </div>
          </li> -->
          <li v-if="!!userName">
            <router-link to="/home" :class="currAct === 'home' ? 'active' : ''">主页</router-link>
          </li>
          <li v-if="userRole===1">
            <router-link to="/accountlist" :class="currAct === 'accountlist' ? 'active' : ''">账号管理</router-link>
            <!-- <div class="info_style">
              <span>[账号管理]</span>
            </div> -->
          </li>
          <li v-if="!!userName">
            <router-link to="/password" :class="currAct === 'password' ? 'active' : ''">修改密码</router-link>
            <!-- <div class="info_style">
              <span>[修改密码]</span>
            </div> -->
          </li>
          <li>
            <div class="info_style">
              <span style="line-height: 20px">当前用户：{{userRoleName}} <br /><b>{{currDateTime}}</b>{{currWeek}}</span>
            </div>
          </li>
          <li v-if="!!userName">
            <div class="logout" title="退出">
              <!-- <span>{{userName}}</span> -->
              <i @click="handleLogOut"></i>
            </div>
          </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      navStyle: {
        display: ''
      },
      navStyleHeight: '',
      currAct: '',
      clientHeight: 0,
      clientWidth: 0,
      roleList: {
        1: '超级管理员',
        3: '普通管理员'
      },
      weekList: {
        Sunday: '星期日',
        Monday: '星期一',
        Tuesday: '星期二',
        Wednesday: '星期三',
        Thursday: '星期四',
        Friday: '星期五',
        Saturday: '星期六'
      },
      currDateTime: '',
      currWeek: '',
      timer: null
    }
  },
  mounted(){
    this.clientHeight = document.documentElement.clientHeight
    this.clientWidth = document.documentElement.clientWidth
    this.getDateTime()
  },
  watch: {
    $route () {
      console.log('this.$route', this.$route)
      // this.currAct = this.$route.name
      this.currAct = this.$route.matched[0].name
    }
  },
  computed: {
    userName () {
      return this.$store.state.user.userName
    },
    userRole () {
      return this.$store.state.user.roleId
    },
    userRoleName () {
      let userName = this.$store.state.user.userName
      let userRole = this.$store.state.user.roleId
      if(userName) {
        return `${this.roleList[userRole]}-${userName}`
      } else {
        return '未登录'
      }
    }
  },
  methods: {
    ...mapActions([
      'logOut'
    ]),
    getDateTime () {
      this.timer = setInterval(() => {
        this.currDateTime = moment().format('YYYY年MM月DD日 HH:mm:ss')
        this.currWeek = this.weekList[moment().format('dddd')]
      }, 1000)
    },
    toggleNav () {
      this.navStyle.display = this.navStyle.display === 'block' ? 'none' : 'block';
    },
    handleLogOut () {
      this.logOut().then((res) => {
        this.$router.push({
          name: 'login'
        })
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/config.scss';
$imagePath: '~@/assets/images/';
$imagePath2: '~@/assets/images/fowl/';

nav {
  width: 100%;
  min-width: 1260px;
  height: 94px;
  // margin-bottom: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  background-image: linear-gradient(90deg, #0c1744, #17459a);
  box-shadow: 0px 15px 15px rgba(0,0,0,0.9);
  & > div {
    width: 100%;
    height: 100%;
    padding: 19px 40px;
    margin: 0 auto;
    background: url($imagePath2 + 'header_bg.png') right center no-repeat;
    & > div {
      float: left;
      & > a {
        display: inline-block;
        // width: 190px;
        height: 56px;
        line-height: 56px;
        background-image: linear-gradient(180deg, #32EB78, #138EF5);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-size: 36px;
        // background: url($imagePath2 + 'logo.png') center no-repeat;
        // background-size: contain;
        vertical-align: middle;
      }
      & > p {
        display: inline-block;
        line-height: 28px;
        color: #17c0ca;
        font-size: 24px;
        vertical-align: middle;
        margin-left: 30px;
        span {
          font-size: 16px;
          // padding-left: 20px;
          vertical-align: bottom;
        }
      }
    }
    
    ul {
        float: right;
        list-style: none;
        li {
            float: left;
            padding: 8px 5px;
            position: relative;
            a,span {
                display: inline-block;
                padding: 0 15px;
                height: 40px;
                line-height: 36px;
                border: 2px solid transparent;
                border-radius: 20px;
                font-size: 20px;
                color: $baseColor;
                font-weight: bold;
                &.active {
                    border-color: $baseColor;
                }
            }
            .info_style {
              a,span {
                font-size: 16px;
                font-weight: normal;
                b {
                  display: inline-block;
                  width: 200px;
                  font-weight: normal;
                }
              }
            }
            .item-list {
              text-align: center;
              display: none;
              width: 100%;
              position: absolute;
              left: 0;
              top: 75px;
              background-color: #fff;
              box-shadow: 1px 6px 6px rgba(0,0,0,0.1);
              z-index: 9;
              padding: 10px 0;
              a {
                font-size: 16px;
              }
              a:hover {
                background-color: #eeeeee;
              }
            }
            &:hover {
              .item-list {
                display: block;
              }
            }
            .logout {
                height: 40px;
                padding: 6px 10px;
                &>i {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    margin-left: 20px;
                    background: url($imagePath2 + 'close_icon.svg') center no-repeat;
                }
            }
        }
    }
  }
}

@media (max-width: 767px) {
  nav {
        width: 100%;
        height: $mobileHeaderHeight;
        padding: 5px 10px;
        position: fixed;
        z-index: 1;
        top: 0;
        & > a {
            width: 220px;
        }
        button {
            display: block;
            background-color: #ffffff;
            border: 1px solid #dddddd;
            padding: 5px;
            width: 44px;
            height: 40px;
            line-height: 0px;
            border-radius: 5px;
            float: right;
            margin: 17px 10px;
            span {
                display: inline-block;
                width: 22px;
                height: 2px;
                background-color: #888888;
                margin: 2px 0;
            }
        }
        ul {
            display: none;
            position: absolute;
            left: 0;
            top: 80px;
            z-index: 1;
            // background-color: #ffffff;
            width: 100%;
            // padding: 20px 10px;
            box-shadow: 3px 6px 6px rgba(0,0,0,0.1);
            li {
                width: 100%;
                padding: 15px 10px;
                background-color: #ffffff;
                a {
                    width: 100%;
                    font-size: 16px;
                }
                .item-list {
                  text-align: left;
                  position: initial;
                  box-shadow: unset;
                  display: block;
                  padding: 10px 15px;
                }
                .logout {
                  padding-left: 0;
                  justify-content: center;
                }
            }
            // &.show {
            //     display: block;
            // }
        }
    }
}
</style>
