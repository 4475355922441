import http from '@/libs/http'
import { hasTypeApiUrl } from '@/libs/util'

/*登录*/
export const login = (data) => {
    return http.request({
        url: '/api/account/login',
        data,
        method: 'post'
    })
}

/*退出*/
export const logout = () => {
    return http.request({
        url: '/api/account/logout',
        method: 'post'
    })
}

/*获取用户信息*/
export const getUserInfo = (params) => {
    return http.request({
        url: '/api/account/info',
        params,
        method: 'get'
    })
}

// 鸡相关

/**
 * type=pore 检测鸡的毛孔 type=breed 检测鸡的种类
 * @returns 
 */
export const submitChickenFun = (type, data) => {
    return http.request({
      url: hasTypeApiUrl(type),
      data,
      method: 'post',
    })
}
/**
 * 获取检测鸡相关的列表
 * skip    跳过多少，默认0
 * limit   总共返回多少，默认15
 * status根据状态过滤，1 表示等待中，2表示处理中，3表示处理成功，4表示处理完成但是失败  
  start_time  开始时间，end_time截止时间
  分页参数：skip（跳过多少），limit（返回多少）
 */
export const getChickenListFun = (type, params) => {
    return http.request({
        url: hasTypeApiUrl(type),
        params,
        method: 'get'
    })
}
export const getChickenCountFun = (type, params) => {
    return http.request({
        url: hasTypeApiUrl(type) + '/count',
        params,
        method: 'get'
    })
}
export const updateChickenFun = (type, id, data) => {
    return http.request({
      url: `${hasTypeApiUrl(type)}/${id}`,
      data,
      method: 'put',
    })
}

export const getChickenIdListFun = (params) => {
    return http.request({
        url: 'api/chicken',
        params,
        method: 'get'
    })
}
export const getChickenIdCountFun = (params) => {
    return http.request({
        url: 'api/chicken/count',
        params,
        method: 'get'
    })
}
export const getChickenIdDetailFun = (id) => {
    return http.request({
        url: 'api/chicken/' + id,
        method: 'get'
    })
}
export const updateChickenInfoFun = (id, data) => {
    return http.request({
      url: 'api/chicken/' + id,
      data,
      method: 'put',
    })
}
export const deleteChickenIdFun = (id) => {
    return http.request({
      url: 'api/chicken/' + id,
      method: 'delete',
    })
}
export const getChickenSummaryFun = (params) => {
    return http.request({
        url: 'api/chicken/summary',
        params,
        method: 'get'
    })
}
export const getChickenStatFun = (params) => {
    return http.request({
        url: 'api/chicken/count-images',
        params,
        method: 'get'
    })
}
/**
 * 新增品种
 * @param {*} data {"name": "American Gamefowl", "country": "美国", "description": "美国大公鸡"}
 * @returns 
 */
export const addBreedApi = (data) => {
    return http.request({
      url: 'api/chicken/breed',
      data,
      method: 'post',
    })
}
export const getBreedListApi = (params) => {
    return http.request({
        url: 'api/chicken/breed',
        params,
        method: 'get'
    })
}
export const getBreedCountApi = (params) => {
    return http.request({
        url: 'api/chicken/breed/count',
        params,
        method: 'get'
    })
}
export const updateBreedApi = (id, data) => {
    return http.request({
      url: `api/chicken/breed/${id}`,
      data,
      method: 'put',
    })
}
export const getBreedDetailApi = (id) => {
    return http.request({
        url: `api/chicken/breed/${id}`,
        method: 'get'
    })
}
export const uploadBreedImagesUrlApi = (id, data) => {
    return http.request({
      url: `api/chicken/breed/${id}/image`,
      data,
      method: 'post',
    })
}
export const deleteBreedImageApi = (breedId, imageId) => {
    return http.request({
        url: `api/chicken/breed/${breedId}/image/${imageId}`,
        method: 'delete'
    })
}

/**
 * 新增鸡蛋品种
 * @param {*} data {"name": "竹丝", "model_infer_name": "zhusi", "country": "中国", "description": "竹丝鸡蛋"}
 * @returns 
 */
 export const addEggBreedApi = (data) => {
    return http.request({
      url: 'api/egg/type',
      data,
      method: 'post',
    })
}
export const getEggBreedListApi = (params) => {
    return http.request({
        url: 'api/egg/type',
        params,
        method: 'get'
    })
}
export const getEggBreedCountApi = (params) => {
    return http.request({
        url: 'api/egg/type/count',
        params,
        method: 'get'
    })
}
export const updateEggBreedApi = (id, data) => {
    return http.request({
      url: `api/egg/type/${id}`,
      data,
      method: 'put',
    })
}
export const getEggBreedDetailApi = (id) => {
    return http.request({
        url: `api/egg/type/${id}`,
        method: 'get'
    })
}
export const uploadEggBreedImagesUrlApi = (id, data) => {
    return http.request({
      url: `api/egg/type/${id}/image`,
      data,
      method: 'post',
    })
}
export const deleteEggBreedImageApi = (breedId, imageId) => {
    return http.request({
        url: `api/egg/type/${breedId}/image/${imageId}`,
        method: 'delete'
    })
}

// 
export const emailModelUpgradeApplyApi = () => {
    return http.request({
      url: 'api/chicken/breed/notify-identify-model-upgrade',
      method: 'post',
    })
}

// 3d点云模型相关接口
export const getModelingListApi = (params) => {
    return http.request({
        url: 'api/chicken/modeling',
        params,
        method: 'get'
    })
}
export const getModelingCountApi = (params) => {
    return http.request({
        url: 'api/chicken/modeling/count',
        params,
        method: 'get'
    })
}

const cameraBaseUrl = 'http://192.168.2.12:9998'
export const openCameraApi = () => {
    return http.request({
      baseUrl: cameraBaseUrl,
      url: 'api/chicken/camera/open',
      method: 'post',
    })
}
export const stopCameraApi = () => {
    return http.request({
      baseUrl: cameraBaseUrl,
      url: 'api/chicken/camera/stop',
      method: 'post',
    })
}

// mp3
export const getCluckListApi = (params) => {
    return http.request({
        url: 'api/chicken/cluck/tag',
        params,
        method: 'get'
    })
}
export const getCluckCountApi = (params) => {
    return http.request({
        url: 'api/chicken/cluck/tag/count',
        params,
        method: 'get'
    })
}
export const addCluckTagApi = (data) => {
    return http.request({
      url: 'api/chicken/cluck/tag',
      data,
      method: 'post',
    })
}
export const modifyCluckTagApi = (id, data) => {
    return http.request({
      url: `api/chicken/cluck/tag/${id}`,
      data,
      method: 'put',
    })
}
// 修改密码
export const modifyPasswordApi = (data) => {
    return http.request({
      url: 'api/account/change-password',
      data,
      method: 'post',
    })
}
//  账号管理
export const addAccountApi = (data) => {
    return http.request({
      url: 'api/admin/sub-account',
      data,
      method: 'post',
    })
}
export const getAccountListApi = (params) => {
    return http.request({
      url: 'api/admin/sub-account',
      params,
      method: 'get',
    })
}
export const getAccountCountApi = (params) => {
    return http.request({
        url: 'api/admin/sub-account/count',
        params,
        method: 'get'
    })
}
export const modifyAccountPasswordApi = (data) => {
    return http.request({
      url: 'api/admin/sub-account/change-password',
      data,
      method: 'post',
    })
}

