import Vue from 'vue'
import { Input, Upload, Pagination, Menu, Submenu, MenuItem, Popover, Dialog,
  Dropdown, DropdownMenu, DropdownItem, Form, FormItem, Tag, Radio,
  RadioGroup, Button,
  RadioButton,Select,
  Option,
  Table,
  TableColumn,
  DatePicker,
  Switch
} from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/global.scss'
import './assets/styles/iconfont/iconfont.css'

Vue.config.productionTip = false;
Vue.use(Input);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Popover);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Switch);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
