import axios from 'axios'
import { Message } from 'element-ui'
// import store from '@/store'
// import { Spin } from 'iview'
import config from '@/config'
import { getToken, getAccountId, setToken, setAccountId } from './util'

// const addErrorLog = errorInfo => {
//   const { statusText, status, request: { responseURL } } = errorInfo
//   let info = {
//     type: 'ajax',
//     code: status,
//     mes: statusText,
//     url: responseURL
//   }
//   if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info)
// }

class HttpRequest {
  constructor (baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        'account-id': getAccountId(),
        'Authorization': getToken()
      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
        // Spin.show() // 不建议开启，因为界面不友好
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use((res) => {
      this.destroy(url)
      const { data, status } = res
      if (data.result_code === 5) {
        setToken('')
        setAccountId('')
        setTimeout(() => {
          window.location.href = '/login'
        }, 1000)
      }
      if (data.result_code !== 0) {
        Message.error(data.err_detail)
        return Promise.reject()
      }
      return Promise.resolve({ data })
    }, (error) => {
      const err = JSON.parse(JSON.stringify(error))
      this.destroy(url)
      if(error.response){
        Message.error(error.response.data.message || '未知错误')
      }else {
        if(err.code === "ECONNABORTED"){
          Message.error('请求超时！')
        }
      }
      console.log(JSON.parse(JSON.stringify(error)))
      // let errorInfo = error.response
      // if (!errorInfo) {
      //   const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
      //   errorInfo = {
      //     statusText,
      //     status,
      //     request: { responseURL: config.url }
      //   }
      // }
      // addErrorLog(errorInfo)
      return Promise.reject(error)
    })
  }
  request (options) {
    axios.defaults.timeout = 30000
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    if(options.baseUrl) options.baseURL = options.baseUrl;
    return instance(options)
  }
}

const http = new HttpRequest(config.baseUrl)
export default http
