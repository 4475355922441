import Vue from 'vue'
import Router from 'vue-router'
import { setToken, getToken, setAccountId, canTurnTo } from '@/libs/util'
import store from '@/store'
import routes from './routes'

Vue.use(Router)


const createRouter = () => new Router({
    routes,
    mode: 'history', // require service support
    scrollBehavior: () => ({y: 0})
})

const router = createRouter()
const LOGIN_PAGE_NAME = 'login'
const HOME_PAGE_NAME = 'home'
const NO_LOGIN_PAGE = ['home', 'help', '3d', 'three', 'modeling', 'login']

const turnTo = (to, access, next) => {
    if (canTurnTo(to.name, access, routes)) next() // 有权限，可访问
    else next({ replace: true, name: '404' }) // 无权限，重定向到404页面
}

router.beforeEach((to, from, next) => {
    const token = getToken()
    if(to.meta.title) {
        document.title = to.meta.title
    }
    if(token) {
        store.dispatch('getUserInfo').then(user => {
            // 拉取用户信息，通过用户权限和跳转的页面的name来判断是否有权限访问;access必须是一个数组，如：['super_admin'] ['super_admin', 'admin']
            let role = [user.role_id]
            if(to.name === LOGIN_PAGE_NAME) {
                if(role[0] === 0) {
                    next({
                        name: 'management'
                    })
                } else {
                    next({
                        name: HOME_PAGE_NAME
                    })
                }
            } else {
                turnTo(to, role, next)
            }

        }).catch(() => {
            setToken('')
            setAccountId('')
            next({
                name: 'login'
            })
        })
    } else {
        // if(to.name !== LOGIN_PAGE_NAME) {
        //     next({
        //         name: LOGIN_PAGE_NAME
        //     })
        // } else {
        //     next()
        // }
        if(NO_LOGIN_PAGE.includes(to.name)) {
            next()
        } else {
            next({
                name: LOGIN_PAGE_NAME
            })
        }
    }
})

router.afterEach(() => {
// finish progress bar
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router