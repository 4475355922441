import { login, logout, getUserInfo, modifyPasswordApi } from '@/api/user'
import { setToken, getToken, setAccountId, getAccountId } from '@/libs/util'
import config from '@/config'

export default {
    state: {
        userName: '',
        token: getToken(),
        accountId: getAccountId(),
        roles: [],
        roleId: null
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            setToken(token)
        },
        setAccountId (state, accountId) {
            state.accountId = accountId
            setAccountId(accountId)
        },
        setUserName (state, name) {
            state.userName = name
        },
        setRoles(state, roles) {
            state.roles = roles
        },
        setRoleId(state, roleId) {
            state.roleId = roleId
        }
    },
    getters: {},
    actions: {
        logIn({state, commit}, params) {
            return new Promise((resolve, reject) => {
                login(params).then((res) => {
                    const data = res.data.data
                    commit('setToken', data.token)
                    commit('setAccountId', data.id)
                    commit('setRoleId', data.role_id)
                    resolve(data)
                }).catch(err => {
                    reject(err)
                })
                // if(params.name === config.testUser.account && params.password === config.testUser.password) {
                //     commit('setToken', 'hajay')
                //     commit('setRoles', [1])
                //     resolve(config.testUser)
                // } else if (params.name === config.testAdmin.account && params.password === config.testAdmin.password) {
                //     commit('setToken', 'hajayAdmin')
                //     commit('setRoles', [0])
                //     resolve(config.testAdmin)
                // } else {
                //     login(params).then((res) => {
                //         const data = res.data
                //         commit('setToken', data.token)
                //         commit('setRoles', [1])
                //         resolve(data)
                //     }).catch(err => {
                //         reject(err)
                //     })
                // }
            })
        },
        logOut({state, commit}, params) {
            return new Promise((resolve, reject) => {
                logout(params).then((res) => {
                    commit('setToken', '')
                    commit('setAccountId', '')
                    commit('setUserName', '')
                    commit('setRoles', [])
                    commit('setRoleId', null)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getUserInfo({state, commit}) {
            return new Promise((resolve, reject) => {
                try {
                    getUserInfo().then(res => {
                      const data = res.data.data
                      // commit('setAvatar', data.avatar)
                      commit('setUserName', data.name)
                      commit('setRoles', [data.role_id])
                      commit('setRoleId', data.role_id)
                      // commit('setUserId', data.user_id)
                      // commit('setAccess', data.access)
                      // commit('setHasGetInfo', true)
                      resolve(data)
                    }).catch(err => {
                      reject(err)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        modifyPwd({state, commit}, params) {
            return new Promise((resolve, reject) => {
                modifyPasswordApi(params).then(res => {
                    commit('setToken', '')
                    commit('setAccountId', '')
                    commit('setUserName', '')
                    commit('setRoles', [])
                    commit('setRoleId', null)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}