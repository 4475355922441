import Cookies from 'js-cookie'
// cookie保存的天数
import config from '@/config'
const { cookieExpires } = config

export const TOKEN_KEY = 'token'
export const ACCOUNTID_KEY = 'accountId'

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires || 1 })
}
export const setAccountId = (accountId) => {
  Cookies.set(ACCOUNTID_KEY, accountId, { expires: cookieExpires || 1 })
}

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY)
  if (token) return token
  else return false
}
export const getAccountId = () => {
  const accountId = Cookies.get(ACCOUNTID_KEY)
  if (accountId) return accountId
  else return false
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
const hasOneOf = (targetarr, arr) => {
  return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {*} roles 用户权限数组，如 [0, 1]
 * @param {*} route 路由列表
 */
 const hasRoles = (roles, route) => {
  if (route.meta && route.meta.roles) return hasOneOf(roles, route.meta.roles)
  else return true
}

/**
 * 拼接共用的url
 * @param {*} type url参数type的值
 * @returns 
 */
 export const hasTypeApiUrl = (type) => {
  if(type === 'breed') {
    return `api/chicken/breed-identify`
  } else if(type === 'egg') {
    return `api/egg/type-identify`
  } else if(type === 'feather' || type === 'head') {
    return `api/poultry/${type}`
  }else {
    return `api/chicken/${type}`
  }
}

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} roles 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
 export const canTurnTo = (name, roles, routes) => {
  const routePermissionJudge = (list) => {
    return list.some(item => {
      if (item.children && item.children.length) {
        return routePermissionJudge(item.children)
      } else if (item.name === name) {
        return hasRoles(roles, item)
      }
    })
  }

  return routePermissionJudge(routes)
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
 export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}